var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"download-button"},[_c('div',{staticClass:"download-item row",class:{
      available: _vm.downloadItem.length > 0
    }},[_c('div',{staticClass:"col-12 d-flex align-items-center"},[_c('span',{staticClass:"download-item--icon mr-2",class:{
          available: _vm.downloadItem.length > 0
        }},[_c('i',{staticClass:"fas",class:{
            'fa-check': _vm.downloadItem.length > 0,
            'fa-times': _vm.downloadItem.length < 1
          }})]),_c('span',[_vm._v(_vm._s(_vm.label))])]),_vm._l((_vm.downloadItem),function(item,index){return _c('div',{key:index,staticClass:"col-12"},[_c('div',{staticClass:"d-flex align-items-center justify-content-between"},[_c('div',{staticClass:"download-item-section"},[_c('p',{staticClass:"download-item-label"},[_vm._v(" "+_vm._s(_vm.elipsisMiddle( (_vm.filenamePrefix + "_" + index + "." + (_vm.getFileExtension( item.fileName ))), { startLength: 14, endLength: 6, trimAt: 20 } ))+" ")])]),_c('button',{staticClass:"btn main sm bordered ml-2",on:{"click":function($event){return _vm.$emit('download', item, (_vm.filenamePrefix + "_" + index), _vm.setLoading)}}},[_c('span',{staticClass:"d-flex justify-content-center align-items-center"},[(_vm.isDownloading)?_c('spinner',{attrs:{"size":"14px","color":"white"}}):_c('i',{staticClass:"fas fa-download"}),_c('span',{staticClass:"d-none sm-d-inline-block ml-1"},[_vm._v("Download")])],1)])])])})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }