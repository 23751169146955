<template>
  <div class="download-button">
    <div
      class="download-item row"
      :class="{
        available: downloadItem.length > 0
      }"
    >
      <!-- Parent label -->
      <div class="col-12 d-flex align-items-center">
        <span
          class="download-item--icon mr-2"
          :class="{
            available: downloadItem.length > 0
          }"
        >
          <i
            class="fas"
            :class="{
              'fa-check': downloadItem.length > 0,
              'fa-times': downloadItem.length < 1
            }"
          ></i>
        </span>
        <span>{{ label }}</span>
      </div>

      <!-- Download Items -->
      <div v-for="(item, index) in downloadItem" :key="index" class="col-12">
        <div class="d-flex align-items-center justify-content-between">
          <div class="download-item-section">
            <p class="download-item-label">
              {{
                elipsisMiddle(
                  `${filenamePrefix}_${index}.${getFileExtension(
                    item.fileName
                  )}`,
                  {
                    startLength: 14,
                    endLength: 6,
                    trimAt: 20
                  }
                )
              }}
            </p>
          </div>
          <button
            class="btn main sm bordered ml-2"
            @click="
              $emit('download', item, `${filenamePrefix}_${index}`, setLoading)
            "
          >
            <span class="d-flex justify-content-center align-items-center">
              <spinner v-if="isDownloading" size="14px" color="white"></spinner>
              <i v-else class="fas fa-download"></i>
              <span class="d-none sm-d-inline-block ml-1">Download</span>
            </span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { elipsisMiddle, getFileExtension } from "@/utils/string";

export default {
  components: {
    Spinner: () =>
      import("@/components/GlobalComponents/LoaderComponent/Spinner")
  },
  mixins: [],
  props: {
    downloadItem: {
      type: Array,
      required: true,
      default: () => {
        [];
      }
    },
    label: {
      type: String
    },
    filenamePrefix: {
      type: String,
      default: "download"
    }
  },
  data: function () {
    return {
      isDownloading: false,

      elipsisMiddle,
      getFileExtension
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {
    setLoading(boolean) {
      this.isDownloading = boolean;
    }
  }
};
</script>

<style lang="scss">
.download-item {
  border: 1px solid#ccc;
  padding: 6px 12px;
  border-radius: 5px;
  margin-bottom: 5px;
  min-height: 54px;
  .download-item--icon {
    display: flex;
    align-items: center;
    justify-content: center;
    background: $color-danger;
    color: white;
    border-radius: 50em;
    width: 24px;
    height: 24px;
    font-size: 12px;

    &.available {
      background: $color-success;
    }
  }
  .download-item-label {
    padding-left: 42px;
  }
}
</style>
